import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useEffect, useState } from 'react';
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { validateService} from "../service/validation.service";
import { apiService} from "../service/api.service";
import swal from 'sweetalert';
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../i18n';
import Loader from "../common/Loader";
const ContactUs = () => {
  const { t } = useTranslation();
  const [loader,setLoader]=useState(false)
  const [contactFieldsErrs, setcontactFieldsErrs] = useState({
    name: "",
    phone:"",
    email:"",
    message:"",
});

const [contactFields, setcontactFields] = useState({
   name: "",
   phone:"",
   email:"",
   message:"",
});
const resetField = ()=>{
  setcontactFields({
     name: "",
     phone:"",
     email:"",
     message:"",
  })

}

useEffect(() => {
  const loginToken = localStorage.getItem("web_token");
  if(loginToken){
    GetProfile()
  }

}, [])
const handleSubmit =async()=>{

  let valid = validateService.validateContactUs(setcontactFieldsErrs,contactFields)

  if(valid){
    setLoader(true)
    apiService.contactUs(contactFields).then((res) => {
      swal("Success","Thank you for reaching out. We have received your message and will be in touch with you shortly. Your patience is appreciated.","success")
      setLoader(false)
      resetField()
     }).catch((err) => { 
      setLoader(false)
   
      // setupdateShow(true)
    });
  }

}
const GetProfile = () => {
  apiService.GetProfile().then((res) => {
      console.log(res.data.data.user.status);
      setcontactFields((prevObj) => {  return { ...prevObj, name: res.data.data.user.firstName+" "+res.data.data.user.lastName}})
      //   setprofileCompleted(res.data.data.user.firstName);
      setcontactFields((prevObj) => {  return { ...prevObj, email: res.data.data.user.email}})
      setcontactFields((prevObj) => {  return { ...prevObj, phone: res.data.data.user.phone}})
      // setnotifyCount(res.data.data.user.notifyCount);

      // localStorage.setItem("profile_status", res.data.data.user.status);
    }).catch((err) => {
      // errorHandling(err.response.status);
      // if (err.response.status === 401) {
      //     swal({ icon: 'error', text: "Your session has been expired, please login again. ", button: "OK" }).then(() => {
      //         localStorage.clear()
      //         window.location.href = "/login"
      //       });

      // }
    });
};
  return (
    <>
     {loader && <Loader/>}
      <div className="height-footer contact-us-section">
        <Container>
          <div className="">
            <div className='product-header-area'>
              <div>
                <h2 className='subheading-under'>{t('contact_us')}</h2>
                <p>{t('feel_free_to_ask')}</p>
              </div>
            </div>
            <Row className='justify-content-center'>
              <Col md={6}>
                <div className='stock-shadow'>
                  <h3 className='subheading-under subheading-under-box'>{t('feel_free_query')}</h3>
                  <Form className="login-body">
                    <Form.Group className="mb-3 position-relative">
                      <Form.Label>{t('full_name')} </Form.Label>
                      <Form.Control type="text" maxLength={30} placeholder={t('enter_full_name')} value={contactFields.name}   onChange={(e) => setcontactFields((prevObj) => {  return { ...prevObj, name: e.target.value}})}/>
                      <span className="errors">{t(contactFieldsErrs.name)}</span>
                    </Form.Group>
                    <Form.Group className="mb-3 position-relative">
                      <Form.Label>{t('phone')}</Form.Label>
                      <PhoneInput
                             onlyCountries={['co', 'ar', // Argentina
                             'bo', // Bolivia
                             'br', // Brazil
                             'cl', // Chile
                             'co', // Colombia
                             'ec', // Ecuador
                             'pe', // Peru
                             'uy', // Uruguay
                             've', // Venezuela
                           ]}
                             country={"co"}
                        // regions={['latin-america']}
                        value={contactFields.phone}
                        onChange={(e) => setcontactFields((prevObj) => {  return { ...prevObj, phone: e}})}
                      />
                           <span className="errors">{t(contactFieldsErrs.phone)}</span>
                    </Form.Group>
                    <Form.Group className="mb-3 position-relative">
                      <Form.Label>{t('email')}</Form.Label>
                      <Form.Control maxLength={50} type="email" placeholder={t('e_g')} value={contactFields.email}
                       onChange={(e) => setcontactFields((prevObj) => {  return { ...prevObj, email: e.target.value}})}
                      />
                           <span className="errors">{t(contactFieldsErrs.email)}</span>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('message')}</Form.Label>
                      <Form.Control as="textarea" rows={3} placeholder={t('enter_message')} maxLength={500}
                      value={contactFields.message}
                       onChange={(e) => setcontactFields((prevObj) => {  return { ...prevObj, message: e.target.value}})}
                      />
                       <span className="errors">{t(contactFieldsErrs.message)}</span>
                    </Form.Group>
                    <Button className="green-btn" onClick={handleSubmit}>{t('send')} </Button>
                  </Form>
                </div>
              </Col>
              <Col md={4}>
                <div className='contact-info'>
                  <img
                    className=""
                    src={require("../assets/images/call.svg").default}
                    alt="img"
                  />
                  <div>
                    <h6>{t('whatsapp_number')}</h6>
                    <p className='m-0 mt-2'>+57(302)845-9238</p>
                  </div>
                </div>
                <div className='contact-info mt-5'>
                  <img
                    className=""
                    src={require("../assets/images/email.svg").default}
                    alt="img"
                  />
                  <div>
                    <h6>{t('email_place')}</h6>
                    <p className='m-0 mt-2'>soporte@semillaapp.com</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};
export default ContactUs;
